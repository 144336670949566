<template>
  <div>
    <v-card class="mb-6">
      <v-card-title>Verbundene Anzeige</v-card-title>
      <v-card-text>Anzeige der Daten des angeschlossenen Displays für jedes Token: <b>{{ token }}</b></v-card-text>
    </v-card>
    <v-card>
      <v-card-text v-if="token">
        <v-btn
          color="error"
          block
          large
          @click="callDisconnect"
        >
          {{ $t('Disconnect') }}
        </v-btn>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text v-if="token">
        <v-btn
          color="warning"
          block
          large
          @click="callCancel"
        >
          {{ $t('Cancel') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
/* eslint-disable import/no-cycle */
import router from '@/router'
import axios from '@axios'

export default {
  methods: {
    callDisconnect() {
      axios.post(`/displays/${this.token}/disconnect`, {
        bue_ds_screen_token: this.token,
      }).then(response => {
        if (response.data.success) {
          localStorage.removeItem('bue_ds_screen_token')
          this.$cookies.remove('bue_ds_screen_token')
          router.push({ name: 'apps-locations-list', params: { message: this.$t('Display successfully disconnected') } })
        } else {
          console.log(`Error: ${response.data.message}`)
        }
      }, error => {
        console.log(`Error: ${error}`)
      })
    },
    callCancel() {
      router.push({ name: 'apps-locations-list' })
    },
  },
  setup() {
    const { token } = router.currentRoute.params

    return {
      token,
    }
  },
}
</script>
